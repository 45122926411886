<template>
  <v-dialog v-model="dialog" width="400" persistent>
    <v-card>
      <v-card-title>
        <span class="headline"
          >Detalle de donación -
          {{ transaction.transaction_authorization }}</span
        >
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="pb-0">
        <v-container class="pb-0">
          <v-row class="pb-0">
            <v-col cols="12" md="12" class="pb-0">
              <v-file-input
                label="Subir comprobante"
                outlined
                hint="En formato PDF"
                persistent-hint
                prepend-icon
                prepend-inner-icon="mdi-pdf-box"
                show-size
                accept="application/pdf"
                v-model="transaction.voucher_file"
              ></v-file-input>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="blue darken-1"
          text
          @click="changeModal()"
          :disabled="isLoading"
        >
          Cancelar
        </v-btn>
        <v-btn
          color="blue darken-1"
          text
          @click="update()"
          :loading="isLoading"
        >
          Subir
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
export default {
  name: 'UploadVoucher',
  data() {
    return {
      isLoading: false,
      dialog: false,
    };
  },
  props: { transaction: Object, clean: { type: Function } },
  methods: {
    ...mapActions({
      openSnackbar: 'Snackbar/openSnackbar',
    }),
    changeModal: function() {
      this.dialog = !this.dialog;
    },
    update: function() {
      this.isLoading = true;
      this.$http
        .post(
          this.api + 'tx/voucher',
          this.toFormData(this.transaction)
        )
        .then(({ data }) => {
          this.openSnackbar({
            type: data.response ? 'success' : 'warning',
            message: data.message,
          });
          if (data.response) {
            this.changeModal();
            this.clean();
          }
        })
        .catch((err) => {
          this.openSnackbar({
            type: 'danger',
            message: 'Error al realizar la petición',
          });
        })
        .then(() => {
          this.isLoading = false;
        });
    },
  },
  computed: {
    ...mapGetters({
      api: 'getApi',
    }),
  },
};
</script>

<style></style>
