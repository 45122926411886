<template>
  <v-dialog v-model="dialog" width="800" persistent>
    <v-card>
      <v-card-title>
        <span class="headline">Detalle de donación</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12" sm="12" md="6" class="font-weight-bold body-1">
              <p class="text-h6 font-weight-black">
                Datos del donante
              </p>
              <p>
                Nombre:
                <span class="font-weight-medium">{{ transaction.name }}</span>
              </p>
              <p>
                DUI/Documento:
                <span class="font-weight-medium">{{ transaction.dui }}</span>
              </p>
              <p>
                NIT:
                <span class="font-weight-medium">{{ transaction.nit }}</span>
              </p>
              <p>
                Correo:
                <span class="font-weight-medium">{{ transaction.email }}</span>
              </p>
              <p>
                Teléfono:
                <span class="font-weight-medium">{{ transaction.phone }}</span>
              </p>
              <p>
                Dirección:
                <span class="font-weight-medium">{{
                  transaction.address
                }}</span>
              </p>
              <p>
                País:
                <span class="font-weight-medium">{{
                  transaction.country
                }}</span>
              </p>
            </v-col>
            <v-col cols="12" sm="12" md="6" class="font-weight-bold body-1">
              <p class="text-h6 font-weight-black">
                Datos de la donación
              </p>
              <p>
                Cantidad:
                <span class="font-weight-medium">{{
                  transaction.transaction_amount
                }}</span>
              </p>
              <p>
                Hora de donación:
                <span class="font-weight-medium">{{
                  transaction.transaction_time
                }}</span>
              </p>
              <p>
                Tipo de donación:
                <span class="font-weight-medium">{{
                  transaction.donation_type
                }}</span>
              </p>
              <p>
                N° de autorización:
                <span class="font-weight-medium">{{
                  transaction.transaction_authorization
                }}</span>
              </p>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="dialog = false">
          Cerrar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  name: 'DetailDonation',
  data() {
    return {
      dialog: false,
      transactionData: {},
    };
  },
  props: { transaction: Object },
  methods: {
    changeModal: function() {
      this.dialog = !this.dialog;
    },
    getDonor: function() {
      let id = this.transaction.id_transaction;
      this.$http.get(this.api + 'tx/one/' + id).then(({ data }) => {
        this.donor = data;
      });
    },
  },
  computed: {
    ...mapGetters({
      api: 'getApi',
    }),
  },
};
</script>

<style></style>
