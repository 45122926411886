<template>
  <v-dialog v-model="dialog" width="800" persistent>
    <v-card>
      <v-card-title>
        <span class="headline">
          Agregar Donación
        </span>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="pb-0">
        <v-container class="pb-0">
          <v-row class="pb-0">
            <v-col cols="12" md="12">
              <v-autocomplete
                label="Donante Frecuente"
                outlined
                hide-details
                required
                v-model="transaction.id_donor"
                :items="donors"
                item-text="name"
                item-value="id_donor"
                :menu-props="{ maxHeight: 150 }"
              ></v-autocomplete>
            </v-col>
          </v-row>
          <v-divider v-if="transaction.id_donor == 0"></v-divider>
          <v-row class="pb-0" v-if="transaction.id_donor == 0">
            <v-col cols="12" md="8" offset-md="2">
              <v-select
                label="Tipo de Persona"
                required
                outlined
                hint="Si es una empresa, seleccione Persona Jurídica"
                persistent-hint
                :items="person_types"
                item-text="person_type"
                item-value="person_type"
                v-model="transaction.person_type"
                @change="personType"
              ></v-select>
            </v-col>

            <v-col ols="12" sm="6" v-if="legal">
              <v-text-field
                label="Nombre de la Institución"
                required
                outlined
                hide-details
                v-model="transaction.institution_name"
              >
              </v-text-field>
            </v-col>
            <v-col cols="12" sm="6" v-if="legal">
              <v-file-input
                label="Subir NIT de la institución"
                outlined
                hint="Copia del NIT en formato PDF"
                persistent-hint
                prepend-icon
                prepend-inner-icon="mdi-pdf-box"
                show-size
                accept="application/pdf"
                v-model="transaction.photo_nit"
              ></v-file-input>
            </v-col>

            <v-col cols="12" sm="12" md="6">
              <v-autocomplete
                label="Seleccione su país"
                outlined
                hide-details
                v-model="transaction.id_country"
                :items="countries"
                item-text="country"
                item-value="id_country"
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" sm="12" md="6">
              <v-text-field
                label="Documento Único de Identidad"
                :hint="hintDui"
                persistent-hint
                type="text"
                required
                outlined
                v-mask="transaction.id_country === 68 ? '########-#' : ''"
                v-model="transaction.dui"
              ></v-text-field>
            </v-col>

            <v-col cols="12" sm="12">
              <v-text-field
                label="Nombre completo según Documento"
                required
                outlined
                hide-details
                v-model="transaction.name"
              >
              </v-text-field>
            </v-col>

            <v-col cols="12" sm="6">
              <v-text-field
                label="Correo electrónico"
                type="email"
                required
                outlined
                hide-details
                v-model="transaction.email"
              >
              </v-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field
                label="Teléfono"
                type="phone"
                required
                outlined
                hide-details
                v-model="transaction.phone"
                v-mask="'####-####'"
              >
              </v-text-field>
            </v-col>

            <v-col cols="12" sm="12" md="12">
              <v-autocomplete
                label="Año de promoción"
                required
                outlined
                persistent-hint
                hint="Si fue ex alumno, por favor indique su promoción"
                v-model="transaction.promotion"
                :items="promotions"
                item-text="promotion"
                item-value="promotion"
              >
              </v-autocomplete>
            </v-col>

            <v-col cols="12" sm="12">
              <v-textarea
                label="Dirección de domicilio"
                required
                type="text"
                rows="3"
                outlined
                hide-details
                v-model="transaction.address"
              ></v-textarea>
            </v-col>
          </v-row>
          <v-divider></v-divider>
          <v-row class="pb-0">
            <v-col cols="12" md="6" class="pb-0">
              <v-text-field
                label="Número de autorización"
                required
                outlined
                hide-details
                v-model="transaction.authorization"
              >
              </v-text-field>
            </v-col>
            <v-col cols="12" md="6" class="pb-0">
              <v-text-field
                label="Cantidad a donar"
                required
                outlined
                prepend-inner-icon="mdi-currency-usd"
                v-model="transaction.transaction_amount"
                hint="La cantidad minima a donar son $ 5.00"
                persistent-hint
              ></v-text-field>
            </v-col>

            <v-col cols="12" sm="12" md="6">
              <v-autocomplete
                label="Seleccione el país"
                outlined
                hide-details
                v-model="transaction.id_country"
                :items="countries"
                item-text="country"
                item-value="id_country"
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" sm="12" md="6">
              <v-menu
                ref="menu"
                v-model="datePicker"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="transaction.date"
                    label="Fecha de donación"
                    readonly
                    outlined
                    hide-details
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="transaction.date"
                  no-title
                  scrollable
                  @input="$refs.menu.save(transaction.date)"
                >
                </v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12" md="6">
              <v-autocomplete
                label="Año lectivo al que desea donar"
                required
                outlined
                hint="Puede elegir a que año lectivo realizar su donación"
                persistent-hint
                v-model="transaction.year"
                :items="years"
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" md="6">
              <v-autocomplete
                label="Donar al programa:"
                outlined
                hint="Seleccione un programa al cual desea donar"
                persistent-hint
                required
                v-model="transaction.id_donation_type"
                :items="typeDonations"
                item-text="donation_type"
                item-value="id_donation_type"
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" md="12" class="text-center">
              <v-checkbox
                label="¿Desea comprobante?"
                hide-details
                class="d-inline-block mt-0 mr-3"
                true-value="1"
                false-value="0"
                v-model="transaction.voucher"
              ></v-checkbox>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="blue darken-1"
          text
          @click="changeModal()"
          :disabled="isLoading"
        >
          Cancelar
        </v-btn>
        <v-btn color="blue darken-1" text @click="add()" :loading="isLoading">
          Agregar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
export default {
  name: 'AddDonation',
  data() {
    return {
      countries: [],
      years: [],
      typeDonations: [],
      donors: [],
      isLoading: false,
      dialog: false,
      datePicker: false,
      //
      person_types: ['Natural', 'Juridica'],
      legal: false,
      promotions: [],
    };
  },
  props: { transaction: Object, clean: { type: Function } },
  mounted() {
    this.getTypeDonations();
    this.getCountries();
    this.getYears();
    this.getDonors();
    this.getPromotions();
  },
  methods: {
    ...mapActions({
      openSnackbar: 'Snackbar/openSnackbar',
    }),
    changeModal: function() {
      this.dialog = !this.dialog;
    },
    add: function() {
      this.isLoading = true;
      this.$http
        .post(
          this.api + 'tx/donation_manual',
          this.toFormData(this.transaction)
        )
        .then(({ data }) => {
          this.openSnackbar({
            type: data.response ? 'success' : 'warning',
            message: data.message,
          });
          if (data.response) {
            this.changeModal();
            this.clean();
          }
        })
        .catch((err) => {
          this.openSnackbar({
            type: 'danger',
            message: 'Error al realizar la petición',
          });
        })
        .then(() => {
          this.isLoading = false;
        });
    },

    getTypeDonations: async function() {
      await this.$http.get(this.api + 'donation_type').then(({ data }) => {
        this.typeDonations = data;
      });
    },

    getCountries: async function() {
      await this.$http.get(this.api + 'country').then(({ data }) => {
        this.countries = data;
      });
    },

    getYears: function() {
      let year = new Date().getFullYear();
      this.years.push(year);
      year++;
      this.years.push(year);
    },
    getDonors: async function() {
      await this.$http
        .get(this.api + 'donor/all/frequent_donors')
        .then(({ data }) => {
          let data_aut = {
            id_donor: 0,
            name: 'Ninguno',
          };
          if (data.length > 0) {
            data.forEach((item) => {
              item.name = item.name + ' (' + item.email + ') ';
            });
          }
          this.donors = [data_aut, ...data];
        });
    },

    getPromotions: function() {
      let notProm = 'No soy ex-alumno';
      this.promotions.push(notProm);
      let year = new Date().getFullYear();
      year--;
      for (year; year > 1960; year--) {
        this.promotions.push(year);
      }
    },

    personType: function() {
      if (this.transaction.person_type == 'Juridica') {
        this.legal = true;
      } else {
        this.legal = false;
      }
    },
  },
  computed: {
    ...mapGetters({
      api: 'getApi',
    }),
    hintDui() {
      return this.legal ? 'Del Representante Legal' : '';
    },
  },
};
</script>

<style></style>
