<template>
  <div>
    <v-banner>
      <div class="d-flex justify-space-between pr-4">
        <p class="text-h5 font-weight-medium mb-0">Historial de donaciones</p>
        <v-btn
          color="primary"
          elevation="0"
          @click="modalAdd"
          v-if="searchInArray(actions, 'Agregar')"
        >
          <v-icon left light> mdi-plus </v-icon>
          <span class="d-none d-sm-flex">Agregar Donación</span>
        </v-btn>
      </div>
    </v-banner>
    <v-container>
      <v-row>
        <v-col cols="12">
          <v-card>
            <v-data-table
              :headers="data.headers"
              :items="data.data"
              :items-per-page="5"
              :search="search"
              :loading="data.load"
              loading-text="Cargando datos..."
              sort-by="name"
              class="elevation-1"
            >
              <template v-slot:top>
                <v-toolbar flat>
                  <v-toolbar-title class="text-h5">
                    Listado de Donaciones
                  </v-toolbar-title>
                  <v-divider class="mx-4" inset vertical></v-divider>
                  <v-spacer></v-spacer>
                  <v-text-field
                    label="Buscar..."
                    v-model="search"
                    append-icon="mdi-magnify"
                    single-line
                    hide-details
                  ></v-text-field>
                  <v-menu bottom left>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn icon v-bind="attrs" v-on="on">
                        <v-icon>mdi-dots-vertical</v-icon>
                      </v-btn>
                    </template>

                    <v-list>
                      <v-list-item>
                        <v-list-item-title>
                          <vue-json-to-csv
                            :json-data="csvData"
                            :labels="labelsData"
                            :disabled="data.data.length == 0"
                            csv-title="Lista De Transacciones"
                            class="black--text"
                          >
                            <v-icon color="green">mdi-file-excel</v-icon>
                            Descargar CSV </vue-json-to-csv
                          >|
                        </v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </v-toolbar>
              </template>
              <template v-slot:[`item.voucher_required`]="{ item }">
                <span v-if="item.voucher_status != null">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <a
                        :href="api + 'pdf/' + item.voucher_status"
                        v-bind="attrs"
                        v-on="on"
                        class="text-decoration-none"
                      >
                        <v-icon color="light-blue darken-3">
                          mdi-download
                        </v-icon>
                      </a>
                    </template>
                    <span>
                      Descargar Comprobante
                    </span>
                  </v-tooltip>
                </span>
                <span v-else>
                  {{ item.voucher_required }}
                </span>
              </template>
              <!-- Actions -->
              <template v-slot:[`item.actions`]="{ item }">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      class="mr-2"
                      color="light-blue darken-3"
                      v-bind="attrs"
                      v-on="on"
                      @click="
                        selectTransaction(item);
                        modalDetail();
                      "
                    >
                      mdi-eye
                    </v-icon>
                  </template>
                  <span>
                    Ver Donación
                  </span>
                </v-tooltip>
                <v-tooltip bottom>
                  <template
                    v-slot:activator="{ on, attrs }"
                    v-if="item.voucher == 1"
                  >
                    <v-icon
                      color="light-green darken-3"
                      v-bind="attrs"
                      v-on="on"
                      @click="
                        selectTransaction(item);
                        modalVoucher();
                      "
                    >
                      mdi-upload
                    </v-icon>
                  </template>
                  <span>
                    Subir Comprobante
                  </span>
                </v-tooltip>
              </template>
              <!-- Si no carga la data -->
              <template v-slot:no-data>
                <v-btn color="primary" @click="getDonations">
                  Recargar
                </v-btn>
              </template>
            </v-data-table>
          </v-card>
        </v-col>
      </v-row>
    </v-container>

    <!-- Modal -->
    <DetailDonation
      ref="mdlDetail"
      :transaction="slcTransaction"
    ></DetailDonation>
    <AddDonation
      ref="mdlAdd"
      :transaction="newTransaction"
      :clean="cleanAdd"
    ></AddDonation>
    <UploadVoucher
      ref="mdlVoucher"
      :transaction="slcTransaction"
      :clean="cleanVoucher"
    ></UploadVoucher>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import VueJsonToCsv from "vue-json-to-csv";
import AddDonation from "./AddDonation";
import DetailDonation from "./DetailDonation";
import UploadVoucher from "./UploadVoucher";

export default {
  name: "AccountingDonations",
  components: {
    DetailDonation,
    AddDonation,
    UploadVoucher,
    VueJsonToCsv,
  },
  data: () => ({
    search: "",
    dialogInfo: false,
    data: {
      load: true,
      headers: [
        { text: "Fecha", value: "transaction_time" },
        { text: "Autorización", value: "transaction_authorization" },
        { text: "Monto", value: "transaction_amount" },
        { text: "Correo", value: "email" },
        { text: "Teléfono", value: "phone" },
        { text: "Destino Donación", value: "donation_type_name" },
        { text: "Nombre", value: "name" },
        { text: "Promoción", value: "promotion" },
        { text: "Tipo de persona", value: "person_type" },
        { text: "Grupo", value: "donor_group_group" },
        { text: "Pais", value: "country_name" },
        { text: "Comprobante", value: "voucher_required" },
        { text: "Año lectivo", value: "year" },
        { text: "Acciones", value: "actions", sortable: false },
      ],
      data: [],
    },
    newTransaction: {},
    slcTransaction: {},
  }),
  mounted() {
    this.getCurrentActions("Historial de Donaciones");
    this.getDonations();
  },
  methods: {
    // actions
    ...mapActions({
      getCurrentActions: "getCurrentActions",
      openSnackbar: "Snackbar/openSnackbar",
    }),
    getDonations: async function() {
      this.data.load = true;
      await this.$http
        .get(this.api + `tx/all/${new Date().getFullYear()}`)
        .then(({ data }) => {
          if (data.length > 0) {
            data.forEach((item) => {
              if (item.transaction_amount != null) {
                item.transaction_amount =
                  "$ " + parseFloat(item.transaction_amount).toFixed(2);
              } else {
                item.transaction_amount = "$ " + parseFloat(0).toFixed(2);
              }
              item.donor_group_group =
                item.donor_group != null ? item.donor_group.group : "Ninguno";
              item.voucher_required = item.voucher == 1 ? "Si" : "No";
            });
          }
          this.data.data = data;
        })
        .catch((err) => {
          this.openSnackbar({
            type: "danger",
            message: "Error al obtener los datos",
          });
        })
        .then(() => {
          this.data.load = false;
        });
    },
    donation(item) {
      this.$router.push("/private/accounting/donations/" + item.id_donation);
    },
    selectTransaction(transaction) {
      this.slcTransaction = transaction;
    },
    downloadVoucher(transaction) {
      this.$http.get(this.api + "pdf/" + transaction.voucher_status);
    },
    modalAdd() {
      this.$refs.mdlAdd.changeModal();
    },
    cleanAdd() {
      this.newTransaction = {};
      this.getDonations();
    },
    modalDetail() {
      this.$refs.mdlDetail.changeModal();
      this.$refs.mdlDetail.getDonor();
    },
    modalVoucher() {
      this.$refs.mdlVoucher.changeModal();
    },
    cleanVoucher() {
      this.slcTransaction = {};
      this.getDonations();
    },
  },
  computed: {
    ...mapGetters({
      api: "getApi",
      actions: "getCurrentActions",
    }),

    labelsData() {
      let headers = {};
      let localHeaders = this.data.headers;
      if (localHeaders.length > 0) {
        localHeaders.forEach((header) => {
          let excluir = ["Acciones"];
          if (!this.searchInArray(excluir, header.text)) {
            headers[header.value] = { title: header.text };
          }
        });
      }
      return headers;
    },

    csvData() {
      let data = [];
      //de esta manera le quito la reactividad a la variables
      let localDataJSON = JSON.stringify(this.data.data);
      let localdata = JSON.parse(localDataJSON);
      //transformando data
      if (localdata.length > 0) {
        localdata.forEach((item) => {
          data.push(item);
        });
      } else {
        data.push({});
      }
      return data;
    },
  },
};
</script>

<style></style>
