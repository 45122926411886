var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-banner',[_c('div',{staticClass:"d-flex justify-space-between pr-4"},[_c('p',{staticClass:"text-h5 font-weight-medium mb-0"},[_vm._v("Historial de donaciones")]),(_vm.searchInArray(_vm.actions, 'Agregar'))?_c('v-btn',{attrs:{"color":"primary","elevation":"0"},on:{"click":_vm.modalAdd}},[_c('v-icon',{attrs:{"left":"","light":""}},[_vm._v(" mdi-plus ")]),_c('span',{staticClass:"d-none d-sm-flex"},[_vm._v("Agregar Donación")])],1):_vm._e()],1)]),_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.data.headers,"items":_vm.data.data,"items-per-page":5,"search":_vm.search,"loading":_vm.data.load,"loading-text":"Cargando datos...","sort-by":"name"},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',{staticClass:"text-h5"},[_vm._v(" Listado de Donaciones ")]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-spacer'),_c('v-text-field',{attrs:{"label":"Buscar...","append-icon":"mdi-magnify","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-menu',{attrs:{"bottom":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}])},[_c('v-list',[_c('v-list-item',[_c('v-list-item-title',[_c('vue-json-to-csv',{staticClass:"black--text",attrs:{"json-data":_vm.csvData,"labels":_vm.labelsData,"disabled":_vm.data.data.length == 0,"csv-title":"Lista De Transacciones"}},[_c('v-icon',{attrs:{"color":"green"}},[_vm._v("mdi-file-excel")]),_vm._v(" Descargar CSV ")],1),_vm._v("| ")],1)],1)],1)],1)],1)]},proxy:true},{key:"item.voucher_required",fn:function(ref){
var item = ref.item;
return [(item.voucher_status != null)?_c('span',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('a',_vm._g(_vm._b({staticClass:"text-decoration-none",attrs:{"href":_vm.api + 'pdf/' + item.voucher_status}},'a',attrs,false),on),[_c('v-icon',{attrs:{"color":"light-blue darken-3"}},[_vm._v(" mdi-download ")])],1)]}}],null,true)},[_c('span',[_vm._v(" Descargar Comprobante ")])])],1):_c('span',[_vm._v(" "+_vm._s(item.voucher_required)+" ")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"color":"light-blue darken-3"},on:{"click":function($event){_vm.selectTransaction(item);
                      _vm.modalDetail();}}},'v-icon',attrs,false),on),[_vm._v(" mdi-eye ")])]}}],null,true)},[_c('span',[_vm._v(" Ver Donación ")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([(item.voucher == 1)?{key:"activator",fn:function(ref){
                      var on = ref.on;
                      var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"light-green darken-3"},on:{"click":function($event){_vm.selectTransaction(item);
                      _vm.modalVoucher();}}},'v-icon',attrs,false),on),[_vm._v(" mdi-upload ")])]}}:null],null,true)},[_c('span',[_vm._v(" Subir Comprobante ")])])]}},{key:"no-data",fn:function(){return [_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.getDonations}},[_vm._v(" Recargar ")])]},proxy:true}],null,true)})],1)],1)],1)],1),_c('DetailDonation',{ref:"mdlDetail",attrs:{"transaction":_vm.slcTransaction}}),_c('AddDonation',{ref:"mdlAdd",attrs:{"transaction":_vm.newTransaction,"clean":_vm.cleanAdd}}),_c('UploadVoucher',{ref:"mdlVoucher",attrs:{"transaction":_vm.slcTransaction,"clean":_vm.cleanVoucher}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }